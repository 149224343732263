<template>
  <div class="flex items-center">
    <slot name="avatar" :admin="compAdmin">
      <UtilAvatar
        :small="small"
        :medium="medium"
        :anon="!compAdmin"
        :data-initial="getInitialsFromName(compName)"
        :src="get(compAdmin, 'profile.picture', undefined)"
        class="text-white mr-2"
      />
    </slot>

    <slot name="name" :adminName="compName" />
  </div>
</template>

<script>
import { get } from 'lodash'
import getInitialsFromName from '@/lib/name-initials'
import UtilAvatar from '@/components/utils/UtilAvatar.vue'

export default {
  components: {
    UtilAvatar
  },
  name: 'UtilAdminUser',
  props: {
    adminId: String,
    adminData: Object,
    showName: Boolean,
    small: Boolean,
    medium: Boolean
  },
  data() {
    return {
      getInitialsFromName,
      get
    }
  },
  computed: {
    compAdmin() {
      let admin = null

      if (this.adminId) {
        admin = this.$store.getters['app/getAdminFromId'](this.adminId)
      } else {
        admin = this.adminData
      }

      return admin
    },

    compName() {
      return get(this.compAdmin, 'profile.name', '')
    }
  }
}
</script>
