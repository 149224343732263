<template>
  <MultiSelect
    :value="value"
    :options="$store.getters['app/admins']"
    @select="onSelect"
    :placeholder="placeholder"
    :showLabels="false"
    :searchable="false"
    :limit="limit"
    :multiple="compSupportsMultiple"
    :openDirection="openDirection"
    :class="{ 'no-border': !showBorder }"
  >
    <template slot="option" slot-scope="{ option }">
      <div
        class="flex items-center"
        :class="{
          'opacity-50':
            (!compSupportsMultiple && compChosenSingleAdmin._id === option._id) ||
            (compSupportsMultiple && value.includes(option._id))
        }"
      >
        <UtilAvatar
          small
          :data-initial="getInitialsFromName(option.profile.name)"
          :src="get(option, 'profile.picture', undefined)"
          class="text-white mr-2"
        />

        <span>{{ option.profile.name }}</span>
      </div>
    </template>

    <template slot="singleLabel">
      <div class="flex items-center">
        <UtilAvatar
          small
          :data-initial="getInitialsFromName(get(compChosenSingleAdmin, 'profile.name', ''))"
          :src="get(compChosenSingleAdmin, 'profile.picture', undefined)"
          class="text-white mr-2"
        />

        <span>{{ get(compChosenSingleAdmin, 'profile.name', '') }}</span>
      </div>
    </template>

    <template slot="tag" slot-scope="{ option }">
      <div class="flex items-center">
        <UtilAdminUser small :adminId="option" />
      </div>
    </template>
  </MultiSelect>
</template>

<script>
import { get, xor } from 'lodash'
import getInitialsFromName from '@/lib/name-initials'
import UtilAvatar from '@/components/utils/UtilAvatar.vue'
import UtilAdminUser from '@/components/utils/UtilAdminUser.vue'

export default {
  components: {
    UtilAvatar,
    UtilAdminUser
  },
  name: 'UtilAssignee',
  props: {
    value: [String, Array],
    showBorder: {
      type: Boolean,
      default: true
    },
    limit: Number,
    placeholder: {
      type: String,
      default: 'Assigned to no-one'
    },
    openDirection: {
      type: String,
      default: 'auto',
      validator: prop => ['auto', 'above', 'below'].includes(prop)
    }
  },
  data() {
    return {
      get,
      getInitialsFromName
    }
  },
  computed: {
    compSupportsMultiple() {
      return Array.isArray(this.value)
    },

    compChosenSingleAdmin() {
      return this.$store.getters['app/getAdminFromId'](this.value)
    }
  },
  methods: {
    onSelect(value) {
      console.log('onSelect', value)

      let id = value._id

      if (!this.compSupportsMultiple) {
        this.$emit('input', id)
        this.$emit('change', id)

        return
      }

      // Add or remove from array if it exists or not
      if (this.value.includes(id)) {
        this.$emit('input', xor(this.value, [id]))
      } else {
        this.$emit('input', this.value.concat([id]))
      }

      this.$emit('change', id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
