<template>
  <div class="bar" :class="heightClass">
    <slot>
      <div
        class="bar-item tooltip"
        :style="`width:${progressPercentage}%;`"
        :data-tooltip="`${progressPercentage}%`"
        role="progressbar"
        :aria-valuenow="progressPercentage"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'UtilProgressBar',
  props: {
    progressPercentage: {
      type: Number,
      default: 0
    },
    heightClass: {
      type: String,
      default: 'h-2'
    }
  }
}
</script>

<style lang="scss" scoped>
.bar {
  @apply bg-gray-lighter rounded;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  .bar-item {
    @apply bg-gray-darker text-sm;
    color: #fff;
    display: block;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    text-align: center;
    width: 0;

    &:first-child {
      @apply rounded-bl rounded-tl;
    }
    &:last-child {
      @apply rounded-br rounded-tr;
      flex-shrink: 1;
    }
  }
}
</style>
