<template>
  <FormInputValidation :name="name" :rules="rules" v-slot="{ errors }">
    <FormLabel v-if="label || $slots.label">
      {{ label }}
      <slot name="label"></slot>
    </FormLabel>

    <div class="flex items-center relative">
      <select
        class="form-select"
        :name="name"
        :disabled="disabled"
        :multiple="multiple"
        :class="[targetClass, { small, bordered }]"
        v-model="compValue"
        @change="$emit('change', $event.target.value)"
      >
        <option v-if="emptyFirstOption && !compLoading" value>
          {{ placeholder ? placeholder : 'Please Select' }}
        </option>

        <option v-if="compLoading && loadingText" value>{{ loadingText }}</option>

        <option v-for="(item, i) in items" :data-meta="item.meta" :value="item.value" :key="`item-${i}`">
          {{ item.label }}
        </option>
      </select>

      <span v-if="suffix" class="ml-1">{{ suffix }}</span>
    </div>

    <FormInputHint :error="errors[0]" :hint="hint">
      <template slot="hint">
        <slot name="hint"></slot>
      </template>
    </FormInputHint>
  </FormInputValidation>
</template>

<script>
import FormInputValidation from '@/components/forms/elements/FormInputValidation'
import FormInputHint from '@/components/forms/elements/FormInputHint'
import FormLabel from '@/components/forms/elements/FormLabel'

export default {
  name: 'FormSelect',
  components: {
    FormInputValidation,
    FormInputHint,
    FormLabel
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number]
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    label: {
      type: String
    },
    rules: {
      type: String
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String
    },
    hint: {
      type: String
    },
    emptyFirstOption: {
      type: Boolean,
      default: true
    },
    targetClass: {
      type: String,
      default: ''
    },
    suffix: String,
    small: Boolean,
    bordered: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    compValue: {
      set(newVal) {
        this.$emit('input', newVal)
      },
      get() {
        return this.value
      }
    },

    compLoading() {
      return !this.items.length
    }
  }
}
</script>

<style lang="scss" scoped>
.form-select {
  @apply text-primary bg-white rounded;
  appearance: none;
  outline: none;
  height: $input-height;
  vertical-align: middle;
  padding-left: 0px;

  &.bordered {
    @apply border border-primary;
    padding: $input-padding;
  }

  &[size],
  &[multiple] {
    height: auto;

    option {
      padding: 0.14rem 0.28rem;
    }
  }

  &:not([multiple]):not([size]) {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23667189'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E")
      no-repeat right 0.49rem center/.8rem 1rem;
    @apply bg-white;
    padding-right: 1.12rem + 0.8rem;
  }

  &:focus {
    @apply shadow;
    @apply border-brand;
  }

  &::-ms-expand {
    display: none;
  }
}

.form-select.small {
  padding: 0 1rem;
  height: $input-height-small;
}

[class='form-select'] {
  width: 100%;
}
</style>
