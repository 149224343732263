<template>
  <div>
    <FontAwesomeIcon :icon="config[priority].icon" class="mr-1" :class="config[priority].class" />
  </div>
</template>

<script>
import { faArrowAltUp, faArrowAltDown } from '@fortawesome/pro-solid-svg-icons'

export default {
  name: 'UtilPriority',
  props: {
    priority: {
      type: String,
      required: true,
      validator: prop => ['LOW', 'MEDIUM', 'HIGH'].includes(prop)
    }
  },
  data() {
    return {
      config: {
        LOW: {
          icon: faArrowAltDown,
          class: 'text-green-400'
        },
        MEDIUM: {
          icon: faArrowAltUp,
          class: 'text-orange-400'
        },
        HIGH: {
          icon: faArrowAltUp,
          class: 'text-red-600'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
