var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MultiSelect',{class:{ 'no-border': !_vm.showBorder },attrs:{"value":_vm.value,"options":_vm.$store.getters['app/admins'],"placeholder":_vm.placeholder,"showLabels":false,"searchable":false,"limit":_vm.limit,"multiple":_vm.compSupportsMultiple,"openDirection":_vm.openDirection},on:{"select":_vm.onSelect},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"flex items-center",class:{
        'opacity-50':
          (!_vm.compSupportsMultiple && _vm.compChosenSingleAdmin._id === option._id) ||
          (_vm.compSupportsMultiple && _vm.value.includes(option._id))
      }},[_c('UtilAvatar',{staticClass:"text-white mr-2",attrs:{"small":"","data-initial":_vm.getInitialsFromName(option.profile.name),"src":_vm.get(option, 'profile.picture', undefined)}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(option.profile.name))])],1)]}},{key:"tag",fn:function(ref){
      var option = ref.option;
return [_c('div',{staticClass:"flex items-center"},[_c('UtilAdminUser',{attrs:{"small":"","adminId":option}})],1)]}}])},[_vm._v(" "),_c('template',{slot:"singleLabel"},[_c('div',{staticClass:"flex items-center"},[_c('UtilAvatar',{staticClass:"text-white mr-2",attrs:{"small":"","data-initial":_vm.getInitialsFromName(_vm.get(_vm.compChosenSingleAdmin, 'profile.name', '')),"src":_vm.get(_vm.compChosenSingleAdmin, 'profile.picture', undefined)}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.get(_vm.compChosenSingleAdmin, 'profile.name', '')))])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }