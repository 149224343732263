<template>
  <div
    class="avatar"
    :class="{ 'extra-large': extraLarge, large, medium, small, 'bg-gray-500': showBackground && !isBot }"
    @click="$emit('click')"
  >
    <slot>
      <img v-if="src" :src="src" referrerpolicy="no-referrer" />
      <img v-if="isBot" src="@/assets/images/placeholders/bot.jpg" />
      <img v-if="anon" src="@/assets/images/placeholders/team-member.jpg" />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'UtilAvatar',
  props: {
    extraLarge: Boolean,
    large: Boolean,
    medium: Boolean,
    small: Boolean,
    showBackground: {
      type: Boolean,
      default: true
    },
    src: String,
    isBot: Boolean,
    anon: Boolean
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  @apply text-sm;
  border-radius: 50%;
  display: inline-block;
  font-weight: 300;
  position: relative;
  vertical-align: middle;
}

.avatar.small {
  @apply w-6 h-6;
}

.avatar.medium {
  @apply w-12 h-12 text-3xl;
}

.avatar.large {
  @apply w-20 h-20 text-5xl;
}

.avatar.extra-large {
  @apply w-48 h-48 text-5xl;
}

.avatar.premium {
  @apply border-blue-700;
}

.avatar::v-deep img {
  border-radius: 50%;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;
}

.avatar-icon,
.avatar-presence {
  background: #fff;
  bottom: 16%;
  height: 50%;
  padding: 0.2rem;
  position: absolute;
  right: 14.64%;
  transform: translate(50%, 50%);
  width: 50%;
  z-index: 1;
}

.avatar-presence {
  @apply bg-gray;
  box-shadow: 0 0 0 0.2rem #fff;
  border-radius: 50%;
  height: 0.5em;
  width: 0.5em;

  &.online {
    @apply bg-success;
  }

  &.busy {
    @apply bg-danger;
  }

  &.away {
    @apply bg-warning;
  }
}

.avatar[data-initial]::before {
  color: currentColor;
  content: attr(data-initial);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
</style>
